<template>
    <div class="registration">
        <div class="registration__heading">
            <h1>Вiдновлення пароля</h1>
        </div>
        <div class="registration__content">
            <form action id="pills-home" @submit.prevent="submitHandler()" autocomplete="off">
                <div v-if="resetSuccess">
                    <div class="alert alert-success" role="alert">
                        <p class="text-center">
                            Ви вдало змінили пароль.
                        </p>
                    </div>
                    <router-link :to="{ name: 'login'}">
                        Увійти
                    </router-link>
                </div>
                <div v-else>
                    <div class="registration__form-group">
                        <label for="email">Email</label>
                        <input :disabled="isLoad"  :class="{'is-invalid': hasError('email')}" type="email" id="email" v-model.trim="email" autocomplete="off"/>
                        <div v-if="hasError('email')" class="invalid-feedback">
                            {{ getError('email') }}
                        </div>
                    </div>
                    <div class="registration__form-group">
                        <label for="password">Новий пароль</label>
                        <input :disabled="isLoad" :class="{ 'is-invalid': hasError('password') }"  type="password" id="password" v-model.trim="password" autocomplete="off"/>
                        <div v-if="hasError('password')" class="invalid-feedback">
                            {{ getError('password') }}
                        </div>
                    </div>
                    <div class="registration__form-group">
                        <label for="passwordConfirmation">Повторіть новий пароль</label>
                        <input :disabled="isLoad" type="password" id="passwordConfirmation" v-model.trim="passwordConfirm" autocomplete="off"/>
                    </div>
                    <button :disabled="isLoad" type="submit" class="submit">
                        <span v-if="isLoad" style="margin-bottom: 4px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Вiдновити
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { resetPassword } from '@/api/auth'

export default {
    name: 'resetPassword',
    data: () => ({
        resetSuccess: false,
        isLoad: false,
        errors: null,
        token: '',
        email: '',
        password: '',
        passwordConfirm: ''
    }),
    created: function() {
        this.token = this.$route.params.token;
    },
    methods: {
        getError(key) {
            return this.errors[key][0]; //return first error
        },
        hasError(key) {
            return _.has(this.errors, key);
        },
        submitHandler:async function () {
            this.errors = null;
            this.isLoad = true;

            await resetPassword({
                token: this.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirm,
            }).then(response => {
                this.isLoad = false;

                if (response?.data) {
                    this.resetSuccess = true;
                }
            }).catch(e => {
                this.isLoad = false;
                this.errors = e.response.data.data;
                return false;
            });
        },
    }
}
</script>

